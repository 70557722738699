const numberFormatter = (number, fractionDigits = 0, thousandSeperator = ',', fractionSeperator = '.') => {
    if (number!==0 && !number || !Number.isFinite(number)) return number
    const frDigits = Number.isFinite(fractionDigits)? Math.min(Math.max(fractionDigits, 0), 7) : 0
    const num = number.toFixed(frDigits).toString()

    const parts = num.split('.')
    let digits = parts[0].split('').reverse()
    let sign = ''
    if (num < 0) {sign = digits.pop()}
    let final = []
    let pos = 0

    while (digits.length > 1) {
        final.push(digits.shift())
        pos++
        if (pos % 3 === 0) {final.push(thousandSeperator)}
    }
    final.push(digits.shift())
    return `${sign}${final.reverse().join('')}${frDigits > 0 ? fractionSeperator : ''}${frDigits > 0 && parts[1] ? parts[1] : ''}`
}

/*** BET LOG ***/
 var blSocket = io.connect(location.protocol + '//' + document.domain + ':' + location.port + '/betlog',
 {
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax' : 5000,
    'reconnectionAttempts': 5 
});

 var blLoaded = false;

 function formatDateStr(date) {
     date = new Date(date);
     var retTime = "";
     retTime += date.toLocaleDateString() + ' ';
     var hours = date.getHours();
     var minutes = date.getMinutes();
     var ampm = hours >= 12 ? 'PM' : 'AM';
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'
     minutes = minutes < 10 ? '0' + minutes : minutes;
     retTime += hours + ':' + minutes + ' ' + ampm;
     return retTime;
 }

 blSocket.on('json', function(message) {
     if (blLoaded) {
        if (message) {
            message = JSON.parse(message);
            updateLog('#betLog', getRowTemplate(message));
        }
     }
 });

 function initialLoad(id, rowTemplate) {
     $(rowTemplate).appendTo($(id)).slideDown("slow");
 }

 function updateLog(id, rowTemplate) {
     var size = $(`${id} > tr`).length;
     var toRemove = 0;
     if (size > 15) {
         toRemove = size - 15;
     }
     for (var i = 0; i < toRemove; i++) {
         toRemove = $(id).children().last();
         toRemove.remove();
     }
     $(rowTemplate).prependTo($(id)).show("slow");
 }

 function getRowTemplate(msg) {
     var winClass = 'lose';
     var profitStr = numberFormatter(parseFloat(msg.profit), 2).toString()
     if (msg.won) {
         winClass = 'win';
         profitStr = '+' + profitStr;
     }
     var rowHtml = `
     <tr class="${winClass} text-center" style="display:none;">
       <td class="hideOnMobile">${msg.id}</td>
       <td>${msg.username}</td>
       <td class="hideOnMobile">${formatDateStr(msg.date)}</td> 
       <td class="hideOnMobile">${msg.result}</td>
       <td>${msg.target}</td>
       <td>${numberFormatter(parseFloat(msg.amount), 2)}</td>
       <td class="hideOnMobile">${msg.payout}</td>
       <td class="profit">${profitStr}</td>
     </tr>
     `;
     return rowHtml;
 }

/***
 * Higher Rollers/Lucky Wins
 */
function getRowTemplateHRLW(msg) {
    var winClass = 'lose';
    var profitStr = numberFormatter(parseFloat(msg.profit), 2).toString()
    if (msg.won) {
        winClass = 'win';
        profitStr = '+' + profitStr;
    }
    var rowHtml = `
    <tr class="${winClass} text-center" style="display:none;">
      <td>${msg.username}</td>
      <td class="hideOnMobile">${formatDateStr(msg.date)}</td> 
      <td class="hideOnMobile">${msg.result}</td>
      <td>${msg.target}</td>
      <td>${numberFormatter(parseFloat(msg.amount), 2)}</td>
      <td class="hideOnMobile">${msg.payout}</td>
      <td class="profit">${profitStr}</td>
    </tr>
    `;
    return rowHtml;
}

function makeHighRollersRequest() {
    $.ajax({
        type: "GET",
        url: $('#betData').data('high-rollers-url'),
        timeout: 100000
    }).fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
    })
    .done(function(data, textStatus, jqXHR) {
        $('#highRollerBlog').empty()
        $.each(JSON.parse(data), function(i, msg) {
            initialLoad('#highRollerBlog', getRowTemplateHRLW(msg));
        });
    });
}

function makeLuckyWinsRequest() {
    $.ajax({
        type: "GET",
        url: $('#betData').data('lucky-wins-url'),
        timeout: 100000
    }).fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
    })
    .done(function(data, textStatus, jqXHR) {
        $('#luckyWinsTable').empty()
        $.each(JSON.parse(data), function(i, msg) {
            initialLoad('#luckyWinsTable', getRowTemplateHRLW(msg));
        });
    });
}


/*** MY BET LOG ***/
var myBlLoaded = false;

$(document).ready(function() {
    $.ajax({
        type: "GET",
        url: $('#betData').data('all-bet-url'),
        timeout: 100000
    }).fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
    })
    .done(function(data, textStatus, jqXHR) {
        $('#betLog').empty()
        $.each(JSON.parse(data), function(i, msg) {
           initialLoad('#betLog', getRowTemplate(msg));
        });
        blLoaded = true;
    });
    if ($('#myBetLog').length == 0) {
        makeHighRollersRequest();
        makeLuckyWinsRequest();
        return;
    }
    // Inject our CSRF token into our AJAX request.
    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", $('#globalData').data('csrf-token'));
            }
        }
    })
    $.ajax({
        type: "GET",
        url: $('#betData').data('mybl-load-url'),
        timeout: 100000
    }).fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
    })
    .done(function(data, textStatus, jqXHR) {
        $('#myBetLog').empty()
        $.each(JSON.parse(data), function(i, msg) {
            initialLoad('#myBetLog', getMyBLRowTemplate(msg));
        });
        myBlLoaded = true;
    });
    makeHighRollersRequest();
    makeLuckyWinsRequest();
});

function addMyBlRow(bet) {
    if (myBlLoaded) {
        updateLog('#myBetLog', getMyBLRowTemplate(bet));
    }
}
window.addMyBlRow = addMyBlRow;

function getMyBLRowTemplate(msg) {
    var winClass = 'lose';
    var profitStr = numberFormatter(parseFloat(msg.profit), 2).toString()
    if (msg.won) {
        winClass = 'win';
        profitStr = '+' + profitStr;
    }
    var rowHtml = `
    <tr class="${winClass} text-center" style="display:none;">
    <td class="hideOnMobile">${msg.id}</td>
    <td class="hideOnMobile">${formatDateStr(msg.date)}</td>
    <td>${msg.result}</td>
    <td>${msg.target}</td>
    <td>${numberFormatter(parseFloat(msg.amount), 2)}</td>
    <td class="hideOnMobile">${msg.server_seed}</td>
    <td class="hideOnMobile">${msg.client_seed}</td>
    <td class="hideOnMobile">${msg.nonce}</td>
    <td class="profit">${profitStr}</td>
    </tr>
    `;
    return rowHtml;
}