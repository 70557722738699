/*** BET BOX ***/
/* Update other useful dater real-time */
var notify = require('../bs-notify.js');

function makeAlert(msg, type, delay = 5000) {
    $.notifyClose();
    var offset = 20;
    var placement = { from: "top", align: "center" };
    if ($('#mobile-hidden').is(':hidden')) {
        offset = 0;
    }
    $.notify(msg, { offset: offset, placement: placement, type: type, delay: delay});
}

function winAlert(roll, profit) {
    makeAlert(`Rolled ${roll} and won ${profit} BAN`, 'success');
}

function loseAlert(roll, loss) {
    loss = Math.abs(parseFloat(loss))
    makeAlert(`Rolled ${roll} and lost ${loss} BAN`, 'danger');
}

/**
 * roundDown - Round a number to n decimal places, always down.
 * 
 * @param {*} inNumber 
 * @param {*} numPlaces 
 */
function roundDown(inNumber, numPlaces) {
    var multiplier = Math.pow(10, numPlaces);
    return Math.floor(inNumber * multiplier) / multiplier;
}

/**
 * getTenthsPlace - Get the digit in the tenths place for any number
 * 
 * @param {*} inNum
 */
function getTenthsPlace(inNum) {
    var decimalNum = inNum - parseInt(inNum);
    return parseInt(decimalNum * 10);
}

function validationAlert(message, autobet = false) {
    var delay = 5000;
    if (autobet) {
        delay = 0;
    }
    var message = message;
    if (autobet) {
        message = 'Autobet canceled: ' + message;
    }
    makeAlert(message, 'warning', delay)
}

const numberFormatter = (number, fractionDigits = 0, thousandSeperator = ',', fractionSeperator = '.') => {
    if (number!==0 && !number || !Number.isFinite(number)) return number
    const frDigits = Number.isFinite(fractionDigits)? Math.min(Math.max(fractionDigits, 0), 7) : 0
    const num = number.toFixed(frDigits).toString()

    const parts = num.split('.')
    let digits = parts[0].split('').reverse()
    let sign = ''
    if (num < 0) {sign = digits.pop()}
    let final = []
    let pos = 0

    while (digits.length > 1) {
        final.push(digits.shift())
        pos++
        if (pos % 3 === 0) {final.push(thousandSeperator)}
    }
    final.push(digits.shift())
    return `${sign}${final.reverse().join('')}${frDigits > 0 ? fractionSeperator : ''}${frDigits > 0 && parts[1] ? parts[1] : ''}`
}


var uiSocket = io.connect(location.protocol + '//' + document.domain + ':' + location.port + '/ui_updates',
{
'reconnection': true,
'reconnectionDelay': 1000,
'reconnectionDelayMax' : 5000,
'reconnectionAttempts': 5 });

uiSocket.on('json', function(message) {
    $('#maxProfit').html(numberFormatter(parseFloat(message.maxprofit), 2));
    $('#maxProfit').data('max-profit', message.maxprofit);
    $('#globalBetCount').html(message.global_bet_count);
    $('#onlineCount').html(message.online);
});

var slider = $('#betSlider').slider({
    formatter: function(value) {
        return value;
    }
});

var hideTimeout;

function animateTooltip(roll) {
    var duration = 1000;
    if (roll > 50) {
        duration = 1500;
    }
    clearTimeout(hideTimeout);
    $('.tooltip.tooltip-main.top').stop();
    $('.tooltip.tooltip-main.top .tooltip-inner').html(roll);
    $('div.tooltip.tooltip-main.top').addClass('show');
    $('.tooltip.tooltip-main.top').css('left', 0);
    $('.tooltip.tooltip-main.top').animate({'left':roll + '%'}, 
                                            {'duration': duration,
                                             'complete': function() {
                                                hideTimeout = setTimeout(function() {
                                                    $('div.tooltip.tooltip-main.top').removeClass('show');
                                                }, 700);
                                            }});
}

$(".slider-container").on({
    mouseenter: function () {
        $('div.tooltip.tooltip-main.top').addClass('show');
    },
    mouseleave:function () {
        $('div.tooltip.tooltip-main.top').removeClass('show');
    }
},'.slider-handle');

slider.on('change', function() {
    clearTimeout(hideTimeout);
    $('.tooltip.tooltip-main.top').stop(true);
    var sliderVal = slider.slider('getValue');
    if (sliderVal < 65 && sliderVal > 20) {
        sliderVal = sliderVal - 0.3;
    } else if (sliderVal <= 20) {
        sliderVal = sliderVal - 0.6;
    }
    $('.tooltip.tooltip-main.top').css('left', sliderVal + '%');
});

$(document).ready(function() {
    var madeRequest = false;
    var lastRequest = new Date();
    var loadingHtml = '<i class="icon icon-bb-square spinner" style="font-size:24px"></i>';
    var rollDice = $('#betData').data('roll-dice-btn');

    function processErrors(data) {
        $.each(data.errors, function(i, error) {
            validationAlert(error, autoActive);
        });
    }

    var win;
    var autoTimeout;
    function processResponse(data, bet) {
        if (typeof data.roll != "undefined") {
            if (!autoStarted) {
                animateTooltip(data.roll);
            }
            if (data.win) {
                win = true;
                winAlert(data.roll, data.profit);
            } else {
                win = false;
                loseAlert(data.roll, data.profit);
            }
            $('#nonceVal').html(data.nonce);
            $("#userBalance").data('balance', data.balance);
            $('#userBalance').html(numberFormatter(parseFloat(data.balance), 2));
            $('#betFormBalance').html(numberFormatter(parseFloat(data.balance), 2));
            if (autoStarted) {
                autoTimeout = setTimeout(nextAutoBet, 250, win, parseFloat(data.profit));
            }
            addMyBlRow(bet);
        } else {
            processErrors(data);
            if (autoStarted) {
                cancelAutobet();
            }
        }
    }

    function sendBet() {
        madeRequest = true;
        var url = $('#betData').data('bet-post-url');
        if (!autoActive) {
            $('#betSubmit').html(loadingHtml);
        }
        $.ajax({
                type: "POST",
                url: url,
                data: $('#betform').serialize(),
                timeout: 100000
            }).fail(function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus);
                if (autoStarted) {
                    cancelAutobet();
                    makeAlert("Autobet canceled due to a server error", "warning", 0);
                }
            })
            .done(function(data, textStatus, jqXHR) {
                processResponse(data.data, data.bet);
            })
            .always(function(dataOrjqXHR, textStatus, jqXHRorErrorThrown) {
                madeRequest = false;
                if (!autoActive) {
                    $('#betSubmit').html(rollDice);
                }
                lastRequest = new Date();
            });
    }

    if ($('#betSubmit').length > 0) {
        $('#betSubmit').html(rollDice);
        $('#betform').submit(function(e) {
            e.preventDefault();
            if (autoActive) {
                return;
            } else if (madeRequest) {
                return;
            }
            if ((new Date().getTime() - lastRequest.getTime()) / 1000 < 0.2) {
                return;
            }
            sendBet();
        });
    }

    // Default values
    var defaultProfit = 0;
    var houseEdge = parseFloat($('#betData').data('house-edge')) / 100;
    var defaultWinChance = 50 - ((houseEdge * 100) / 2)
    var maxWinChance = ((100 / 1.01) - ((100 / 1.01) * houseEdge)).toFixed(2);
    var maxPayout = 100 - (100 * houseEdge);

    function calculateToWin(setSlider) {
        // OVER
        var overAmount = parseFloat(99.99 - chanceAmount.val())
        if (!setSlider) {
            var toSet = 0;
            var sliderVal = parseFloat(slider.slider('getValue'));            
        }
        if (hiddenOverUnderField.val()) { 
            if (setSlider) {
                towinAmount.html(overAmount.toFixed(2));
                slider.slider('setValue', overAmount.toFixed(2));
            } else {
                towinAmount.html(sliderVal.toFixed(2));
            }
        } else {
            if (setSlider) {
                towinAmount.html(chanceAmount.val());
                slider.slider('setValue', chanceAmount.val());
            } else {
                towinAmount.html(sliderVal.toFixed(2));
            }
        }
    }

    var overUnderButton = $('#betform .over_under_btn');
    var hiddenOverUnderField = $('#betform .over_under_field');
    var betAmount = $('#betform .bet_amt');
    var profitAmount = $('#betform .profit_amt');
    var payoutAmount = $('#betform .payout_amt');
    var chanceAmount = $('#betform .chance_amt');
    var towinAmount = $('#betform .towin_amt');
    profitAmount.val(defaultProfit.toFixed(2));
    chanceAmount.val(defaultWinChance.toFixed(2));
    towinAmount.html(chanceAmount.val());
    slider.slider('setValue', defaultWinChance);
    calculatePayout();

    // Locks - lock these values from being changed
    var payoutLocked = false;

    function calculateProfit() {
        var bet = parseFloat(betAmount.val());
        var payout = lastPayout;
        var profit = (bet * payout) - bet;
        if (isNaN(profit)) {
            profit = 0;
        }
        if (bet < 1) {
            profit = roundDown(profit, 2);
        } else {
            profit = profit.toFixed(2);
        }
        profitAmount.val(profit);
    }

    var lastPayout = parseFloat(payoutAmount.val());

    function calculatePayout() {
        if (payoutLocked) {
            return;
        }
        // Do calculations to 10 decimal places
        var payout = roundDown(100 / chanceAmount.val(), 10);
        payout = (payout - payout * houseEdge).toFixed(10);
        // We only display payout to 2 decimal places for cosmetic reasons
        // but standard rounding can be misleading so apply a better algorithm
        var payoutDisplay = parseFloat(payout).toFixed(2);
        if (getTenthsPlace(payout) >= 5) {
            payoutDisplay = roundDown(payout, 2).toFixed(2);
        }
        if (payout < 1.01) {
            payout = 1.01;
            payoutAmount.val(payout.toFixed(2));
            payoutAmount.change();
        } else if (payout > maxPayout) {
            payout = maxPayout;
            payoutAmount.val(payout.toFixed(2));
            payoutAmount.change();
        } else {
            payoutAmount.val(payoutDisplay);
        }
        lastPayout = payout;
    }

    var lastChance = defaultWinChance;

    
    function calculateChance() {
        var chance = 100 / lastPayout;
        chance = chance - chance * houseEdge;
        if (chance < 1) {
            chance = 1;
            chanceAmount.val(chance.toFixed(2));
            chanceAmount.change();
        } else if (chance > maxWinChance) {
            chance = maxWinChance;
            chanceAmount.val(chance.toFixed(2));
            chanceAmount.change();
        } else {
            chanceAmount.val(chance.toFixed(2));
        }
        lastChance = chance;
    }

    function calculateChanceToWin() {
        // OVER
        var sliderVal = parseFloat(slider.slider('getValue'));            
        if (hiddenOverUnderField.val()) { 
            chanceAmount.val((99.99 - sliderVal).toFixed(2));
        } else {
            chanceAmount.val(sliderVal);
        }
    }

    betAmount.on('change keyup paste mouseup', function() {
        calculateProfit();
    });

    Number.prototype.countDecimals = function () {
        if(Math.floor(this.valueOf()) === this.valueOf()) { return 0; }
        return this.toString().split(".")[1].length || 0; 
    }

    chanceAmount.on('change keyup paste mouseup keypress', function(e) {
        if (e.type == 'keypress' && (e.which == 8 || e.which == 46)) {
            // Delete and backspace
            console.log('chance_keypress');
            return;
        } else if (!$.isNumeric(chanceAmount.val()) && chanceAmount.val() != '') {
            chanceAmount.val(lastChance.toFixed(2));
        } else if ($.isNumeric(chanceAmount.val()) && parseFloat(chanceAmount.val()).countDecimals() > 2) {
            chanceAmount.val(parseFloat(chanceAmount.val()).toFixed(2));
        }
        slider.slider('setValue', chanceAmount.val());
        calculatePayout();
        calculateProfit();
        calculateToWin(true);
    });

    chanceAmount.on('focusout', function() {
        var chance = chanceAmount.val();
        if (chance.trim() == '') {
            chance = 1;
        } else {
            if (parseFloat(chance) < 1) {
                chance = 1;
            }
        }
        chanceAmount.val(parseFloat(chance).toFixed(2));
        chanceAmount.change();
    });

    payoutAmount.on('change keyup paste mouseup keypress', function(e) {
        if (e.type == 'keypress' && (e.which == 8 || e.which == 46)) {
            // Delete and backspace
            return;
        } else if (!$.isNumeric(payoutAmount.val()) && payoutAmount.val() != '') {
            payoutAmount.val(lastChance.toFixed(2));
        } else if ($.isNumeric(payoutAmount.val()) && parseFloat(payoutAmount.val()).countDecimals() > 2) {
            payoutAmount.val(parseFloat(payoutAmount.val()).toFixed(2));
        }
        lastPayout = parseFloat($(this).val())
        calculateChance();
        calculateProfit();
        calculateToWin(true);
    });

    payoutAmount.on('focus', function() {
        payoutLocked = true;
    });

    payoutAmount.on('focusout', function() {
        payoutLocked = false;
        var payout = payoutAmount.val();
        if (payout.trim() == '') {
            payout = 1;
        } else {
            if (parseFloat(payout) > maxPayout) {
                payout = maxPayout;
            } else if (parseFloat(payout) < 1.01) {
                payout = 1.01;
            }
        }
        payoutAmount.val(parseFloat(payout).toFixed(2));
        payoutAmount.change();
    });

    slider.on('change', function() {
        calculateToWin(false);
        calculateChanceToWin();
        calculatePayout();
        calculateProfit();
    });

    $('.slider-selection').addClass('slider-green-track');
    $('.slider-track').addClass('slider-red-track');
    overUnderButton.click(function() {
        if (autoStarted) {
            return;
        }
        if (hiddenOverUnderField.val()) {
            hiddenOverUnderField.val('');
            $('#betform .over_under_txt').html('UNDER');
            $('.slider-selection').removeClass('slider-red-track');
            $('.slider-track').removeClass('slider-green-track');
            $('.slider-selection').addClass('slider-green-track');
            $('.slider-track').addClass('slider-red-track');
            // Set slider min/max
            slider.slider('setAttribute', 'max', maxWinChance);
            slider.slider('setAttribute', 'min', 1);
        } else {
            hiddenOverUnderField.val('1');
            $('#betform .over_under_txt').html('OVER');
            $('.slider-selection').removeClass('slider-green-track');
            $('.slider-track').removeClass('slider-red-track');
            $('.slider-selection').addClass('slider-red-track');
            $('.slider-track').addClass('slider-green-track');
            // Set slider min/max
            slider.slider('setAttribute', 'max', 99.99 - 1);
            slider.slider('setAttribute', 'min', 99.99 - maxWinChance);
        }
        calculateToWin(true);
    });

    // Bet field buttons
    function getBalance() {
        return parseFloat($('#userBalance').data('balance'));
    }

    function halfBet() {
        var new_val = $('#bet_amount').val() / 2
        if (new_val < 0) {
            new_val = 0;
        }
        $('#bet_amount').val(new_val.toFixed(2));
        calculateProfit();       
    }

    $('#halfBetBtn').click(function() {
        halfBet();
    });

    function doubleBet() {
        var new_val = $('#bet_amount').val() * 2
        if (new_val > getBalance()) {
            new_val = getBalance();
        }
        $('#bet_amount').val(new_val.toFixed(2));
        calculateProfit();
    }

    $('#doubleBetBtn').click(function() {
        doubleBet();
    });

    function calculateMaxBet() {
        var balance = getBalance();
        var potentialProfit = parseFloat($('#maxProfit').data('max-profit'));
        var potentialBet = potentialProfit / (lastPayout - 1);
        if (balance > potentialBet) {
            return potentialBet.toFixed(2);
        } else {
            return balance;
        }
    }

    function maxClicked() {
        $('#bet_amount').val(calculateMaxBet());
        calculateProfit();     
    }

    $('#maxBetBtn').click(function() {
        maxClicked();
    });

    $(document).on('keypress', function(event) {
        if ($('.chat-menu').hasClass('chat-menu-open')) { 
            return;
        } else if (!$('#betData').data('username')) {
            return;
        } else if ($('#supportModal').hasClass('show')) {
            return;
        } else if (autoActive) {
            return;
        }
        if (event.key == 'a' || event.key == 'A') {
            halfBet();
        } else if (event.key == 's' || event.key == 'S') {
            doubleBet();
        } else if (event.key == 'm' || event.key == 'M') {
            maxClicked();
        } else if (event.key == 'z' || event.key == 'Z') {
            chanceAmount.val(1);
            chanceAmount.change();
        } else if (event.key == 'x' || event.key == 'X') {
            if (chanceAmount.val() <= 1) {
                return;
            }
            chanceAmount.val(parseFloat(chanceAmount.val()) - 1);
            chanceAmount.change();
        } else if (event.key == 'c' || event.key == 'C') {
            chanceAmount.val(parseFloat(chanceAmount.val()) + 1);
            chanceAmount.change();
        } else if (event.key == 'v' || event.key == 'V') {
            chanceAmount.val(100);
            chanceAmount.change();
        } else if (event.key == 'h' || event.key == 'H') {
            if (!hiddenOverUnderField.val()) {
                overUnderButton.click();
            }
            $('#betSubmit').click();
        } else if (event.key == 'l' || event.key == 'L') {
            if (hiddenOverUnderField.val()) {
                overUnderButton.click();
            }
            $('#betSubmit').click();
        }
    });

    // Automated Betting
    var autoActive = false; // Auto bet tab is selected
    var autoStarted = false; // Auto bet started
    var incOnWin = false; // Increase bet on win is selected
    var incOnLoss = false; // Increase bet on loss is selected

    // Params
    var incOnWinAmt = -1;
    var incOnLossAmt = -1;
    var baseBet = -1;
    var stopLoss = -1;
    var stopWin = -1;
    var maxBets = -1;
    var betsMade = 0;
    var autoProfit = 0;

    // Translatable text strs
    var autoBetStartTxt = $('#betData').data('autoplay-start-btn');
    var autoBetStopTxt = $('#betData').data('autoplay-stop-btn');

    $('#manualBet').click(function() {
        if ($(this).hasClass('active')) {
            return;
        } else if (autoStarted) {
            return;
        }
        autoActive = false;
        $('#betSubmit').html(rollDice);
        $('#autoBet').removeClass('active');
        $(this).addClass('active');
        $('.autoOnly').each(function() {
            $(this).hide();
        });
        $('.manualOnly').each(function() {
            $(this).show();
        });
        $('#betform').removeClass('auto');
    });


    $('#autoBet').click(function() {
        if ($(this).hasClass('active')) {
            return;
        } else if (autoStarted) {
            return;
        }
        autoActive = true;
        $('#betSubmit').html(autoBetStartTxt);
        $('#manualBet').removeClass('active');
        $(this).addClass('active');
        $('.manualOnly').each(function() {
            $(this).hide();
        });
        $('.autoOnly').each(function() {
            $(this).show();
        });
        $('#betform').addClass('auto');
    });

    $('#rtbWin').click(function() {
        $('#incWinInput').attr('readonly', true);
        $(this).removeClass('btn-outline-primary');
        $(this).addClass('btn-primary');
        $('#incWin').removeClass('btn-primary');
        $('#incWin').addClass('btn-outline-primary');
        $('#incWinInput').addClass('disabled');
        incOnWin = false;
    });

    $('#incWin').click(function() {
        $('#incWinInput').removeClass('disabled');
        $('#incWinInput').removeAttr('readonly');
        $(this).removeClass('btn-outline-primary');
        $(this).addClass('btn-primary');
        $('#rtbWin').removeClass('btn-primary');
        $('#rtbWin').addClass('btn-outline-primary');
        incOnWin = true;
    });

    $('#rtbLoss').click(function() {
        $('#incLossInput').attr('readonly', true);
        $(this).removeClass('btn-outline-primary');
        $(this).addClass('btn-primary');
        $('#incLoss').removeClass('btn-primary');
        $('#incLoss').addClass('btn-outline-primary');
        $('#incLossInput').addClass('disabled');
        incOnLoss = false;
    });

    $('#incLoss').click(function() {
        $('#incLossInput').removeClass('disabled');
        $('#incLossInput').removeAttr('readonly');
        $(this).removeClass('btn-outline-primary');
        $(this).addClass('btn-primary');
        $('#rtbLoss').removeClass('btn-primary');
        $('#rtbLoss').addClass('btn-outline-primary');
        incOnLoss = true;
    });

    function cancelAutobet() {
        clearTimeout(autoTimeout);
        $('#betSubmit').html(autoBetStartTxt);
        autoStarted = false;
        betsMade = 0;
        autoProfit = 0;
        $("#bet_amount").val(baseBet)
        // Reset RO fields
        $('#bet_amount').removeAttr('readonly');
        $('#win_chance').removeAttr('readonly');
        $('#lineHeight').removeAttr('readonly');
        $('#maxRolls').removeAttr('readonly');
        $('#stopWin').removeAttr('readonly');
        $('#stopLoss').removeAttr('readonly');
        slider.slider('enable');
        if (incOnWin) {
            $('#incWinInput').removeAttr('readonly');
        }
        if (incOnLoss) {
            $('#incLossInput').removeAttr('readonly');
        }
    }

    function nextAutoBet(win, profit) {
        betsMade++;
        autoProfit += profit;
        if (maxBets != -1 && betsMade > maxBets) {
            makeAlert(`Autobet canceled - max bets exceeded (${maxBets})`, "warning", 0);
            cancelAutobet();
            return;
        }
        if (win) {
            if (incOnWin) {
                var newVal = parseFloat($('#bet_amount').val());
                newVal+=newVal * (incOnWinAmt / 100)
                $("#bet_amount").val(newVal)
            } else {
                $("#bet_amount").val(baseBet)
            }
            if (stopWin != -1) {
                if (autoProfit >= stopWin) {
                    makeAlert(`Autobet canceled - stop win triggered: ${autoProfit} BAN`, "warning", 0);
                    cancelAutobet();
                    return;
                }
            }
        } else {
            if (incOnLoss) {
                var newVal = parseFloat($('#bet_amount').val());
                newVal+=newVal * (incOnLossAmt / 100)
                $("#bet_amount").val(newVal)
            } else {
                $("#bet_amount").val(baseBet)
            }         
            if (stopLoss != -1) {
                if (autoProfit < 0) {
                    if (Math.abs(autoProfit) >= Math.abs(stopLoss)) {
                        makeAlert(`Autobet canceled - stop loss triggered: ${autoProfit} BAN`, "warning", 0);
                        cancelAutobet();
                        return;
                    }
                }
            }
        }
        sendBet();
    }

    $('#betSubmit').click(function() {
        if (!autoActive) {
            return;
        } else if (autoStarted) {
            cancelAutobet();
            return;
        }
        baseBet = parseFloat($('#bet_amount').val());
        if (baseBet < 1) {
            makeAlert("Minimum autobet base is 1 BANANO", "warning");
            return;
        }
        // Set RO fields
        $('#bet_amount').attr('readonly', true);
        $('#maxRolls').attr('readonly', true);
        $('#stopWin').attr('readonly', true);
        $('#stopLoss').attr('readonly', true);
        $('#win_chance').attr('readonly', true);
        $('#lineHeight').attr('readonly', true);
        slider.slider('disable');
        if (incOnWin) {
            $('#incWinInput').attr('readonly', true);
        }
        if (incOnLoss) {
            $('#incLossInput').attr('readonly', true);
        }
        // Parse options
        betsMade = 1;
        if (incOnWin) {
            incOnWinAmt = parseFloat($('#incWinInput').val());
            if (isNaN(incOnWinAmt)) {
                incOnWin = false;
                incOnWinAmt = -1;
            }
        } else {
            incOnWinAmt = -1;
        }
        if (incOnLoss) {
            incOnLossAmt = parseFloat($('#incLossInput').val());
            if (isNaN(incOnLossAmt)) {
                incOnLossAmt = -1;
                incOnLoss = false;
            }
        } else {
            incOnLossAmt = -1;
        }
        if ($('#maxRolls').val()) {
            maxBets = parseFloat($('#maxRolls').val());
            if (isNaN(maxBets)) {
                maxBets = -1;
            }
        } else {
            maxBets = -1;
        }
        if ($('#stopWin').val()) {
            stopWin = parseFloat($('#stopWin').val());
            if (isNaN(stopWin)) {
                stopWin = -1;
            }
        } else {
            stopWin = -1;
        }
        if ($('#stopLoss').val()) {
            stopLoss = parseFloat($('#stopLoss').val());
            if (isNaN(stopLoss)) {
                stopLoss = -1;
            }
        } else {
            stopLoss = -1;
        }
        autoStarted = true;
        $('#betSubmit').html(autoBetStopTxt);
        sendBet();
    });
});
