var notify = require('../bs-notify.js');
/** Socket.IO-y and other stuff for chat box **/
/* TODO List:
 *  - Display users message instantly as grayed out/pending rather than wait for on('message')
 *    If server returns an {'error':'....'}, then indicate it failed to send to user
 *  - Give some moderation abilities to specific roles ('admin', 'moderator')
 *  - Give some unique styles to specific roles ^
 */
var channel = "/gchat";
var maxMessages = 50; // maximum messages to display in chat
var loaded = false;

const DEL_ACTION = 'delete';
const BAN_ACTION = 'ban';

var socket = io.connect(location.protocol + '//' + document.domain + ':' + location.port + channel, 
{
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax' : 5000,
    'reconnectionAttempts': 5 });

socket.on("json", function(message) {
    if (!loaded) {
        $('#chatList').empty();
        $.each(JSON.parse(message), function(i, msg) {
            refreshMessages(msg);
        });
        loaded = true;
        scrollToBottom(false);
    }
});

socket.on("message", function(message) {
    if (message.error != null) {
        $.notifyClose();
        var offset = 20;
        var placement = { from: "top", align: "left" };
        if ($('#mobile-hidden').is(':hidden')) {
            offset = 0;
        }
        $.notify(message.error, { type: 'warning', placement: placement, offset: offset });
    } else if (message.delete != null) {
        $(`#chat-${message.delete}`).remove();
    } else {
        refreshMessages(message);
    }
});

// Wrap URLs in strings in <a>
function urlify(text) {
    var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
    return text.replace(urlRegex, function(url) {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        } else {
            return url;
        }
    })
}

function refreshMessages(message) {
    // Size of list
    var chatSize = $('#chatList > li').length;
    if (chatSize > maxMessages) {
        // Number to remove
        var toRemove = chatSize - maxMessages;
        var idx = 0;
        $('#chatList > li').each(function() {
            this.remove();
            idx++;
            if (idx >= toRemove) {
                return false;
            }
        });
    }
    var scrollDown = false;
    var card = $('.card-body');
    if (card[0].scrollHeight - (card.scrollTop() + card.outerHeight()) < 50) {
        scrollDown = true;
    }
    var createdDt = new Date(message.created);
    var formattedDt = formatDate(createdDt);

    var chatHtml = getMessageTemplate(urlify(message.message), message.avatar, message.msg_id, message.username, formattedDt, message.css_class);
    $(chatHtml).hide();
    $(chatHtml).appendTo('#chatList').slideDown('slow');
    if (scrollDown) {
        scrollToBottom(false);
    }
}

function formatDate(date) {
    var currentDt = new Date();
    var diffInHours = Math.abs(currentDt - date) / 36e5;
    var retTime = "";
    if (diffInHours > 24) {
        retTime += date.toLocaleDateString() + ' ';
    }
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    retTime += hours + ':' + minutes + ' ' + ampm;
    return retTime;
}

function getMessageTemplate(message, avatar, msg_id, username, createdDt, css_class) {
    var styleClass = "";
    var isAdmin = $.trim($('#betData').data('is-admin'));
    if (isAdmin != 'true') {
        styleClass = "style=\"display:none;\"";
    }
    var itemHtml = `<li id="chat-${msg_id}" class="left chat-message">
                        <div class="row">
                        <div class="col-auto">
                            <img class="avatar" src="${avatar}" alt="User Avatar" />
                        </div>
                        <div class="col-10">
                            <div class="row">
                                <div class="container m-0 p-0">
                                <strong class="primary-font ${css_class}">${username}</strong>   
                                <div ${styleClass} class="dropdown text-muted font-weight-light float-right" style="margin-top: -2px;">
                                    <a id="dLabel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor:pointer;">
                                    <i class="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu bg-dark" aria-labelledby="dLabel">
                                    <a id="${msg_id}" class="adminChatBanBtn dropdown-item text-secondary chatDropdownMenu" href="#">Ban From Chat</a>
                                    <a id="${msg_id}" class="adminChatDeleteBtn dropdown-item text-secondary chatDropdownMenu" href="#">Delete</a>
                                    </div>
                                </div>
                                <small class="text-muted float-right mr-1">
                                    <span class="far fa-clock"></span>&nbsp;${createdDt}
                                </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="chat-body">
                                    <p style="word-break: break-word;">
                                    ${message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </li>`;
    return itemHtml;
}

function scrollToBottom(animate) {
    var container = $('.chat-card');
    if (animate) {
        container.animate({
            scrollTop: container[0].scrollHeight
        }, "slow");
    } else {
        container.scrollTop(container[0].scrollHeight);
    }
}

$(function() {

    $("#sendMessage").on("click", function() {
        sendMessage()
    });

    $('#messageText').keyup(function(e) {
        if (e.keyCode == 13) {
            sendMessage();
        }
    });

    function sendMessage() {
        var message = $("#messageText").val();
        if (message.length > 250) {
            $.notifyClose();
            var offset = 20;
            var placement = { from: "top", align: "left" };
            if ($('#mobile-hidden').is(':hidden')) {
              offset = 0;
            }
            $.notify('Message length cannot exceed 250 characters', { type: 'warning', placement: placement, offset: offset });
        } else if (message.length > 0) {
            socket.emit('message', {
                data: {
                    message: message
                }
            });
            $("#messageText").val("");
            scrollToBottom(true);
        }
    }

    // ADMIN
    function sendAdminMessage(action, msg_id) {
        if (action == BAN_ACTION) {
            var msg = {
                action: BAN_ACTION,
                msg_id: msg_id
            };
            socket.emit('json', msg);
        } else if (action == DEL_ACTION) {
            var msg = {
                action: DEL_ACTION,
                msg_id: msg_id
            };
            socket.emit('json', msg);
        }
    }
    $('#chatCard').on('click', '.adminChatDeleteBtn', function() {
        sendAdminMessage(DEL_ACTION, $(this).attr('id'))
    });
    $('#chatCard').on('click', '.adminChatBanBtn', function() {
        sendAdminMessage(BAN_ACTION, $(this).attr('id'))
    });
})

/* Overlay Chat */
$('.openbtn').click(function() {
    $('#main').toggleClass('chat-menu-push-toright');
    $('#chat-menu-left').toggleClass('chat-menu-open');
});
$('.closebtn').click(function() {
    $('#main').toggleClass('chat-menu-push-toright');
    $('#chat-menu-left').toggleClass('chat-menu-open');
});

// Open up chat by default on desktop
$(document).ready(function() {
    if (!$('#mobile-hidden').is(':hidden')) {
        $('.openbtn').click();
    }
}); 